import config from '~/config';
import isUrl from '~/helpers/isUrl';
import { FINGERPRINT_HEADER, FINGERPRINT_HEADER_V2 } from '~/constants';
import useRequest from './useRequest';

const SCOPES = 'offline,openid,offline_access';

const { chototId, fbAppId } = config;

const handleContinueUrl = (continueUrl) => {
  if (isUrl(continueUrl)) {
    return continueUrl;
  }

  return chototId.callback;
};

const useAuthorize = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = async ({ continueUrl = chototId.callback, fingerprintV2 = '' }) => {
    const query = {
      client_id: chototId.clientId,
      scopes: SCOPES,
      continue: handleContinueUrl(continueUrl),
    };

    const { data } = await originFetch(
      `/public/authorize?${new URLSearchParams(query).toString()}`,
      {
        baseUrl: '/api',
        cache: 'no-store',
        headers: {
          [FINGERPRINT_HEADER_V2]: fingerprintV2,
        },
      }
    );

    if (data?.success) {
      const isAuthenticated = data.data.skip_authorize;
      if (isAuthenticated) {
        window.location.href = data.data.redirect_to;
      } else {
        return data.data.login_challenge;
      }
    }

    return '';
  };

  return { ...rest, fetch };
};

const authozieOnServer = async (continueUrl, cookies, headers) => {
  const allowCookies = [
    'oauth2_consent_csrf_insecure',
    'oauth2_authentication_session_insecure',
    'oauth2_authentication_csrf_insecure',
    'ct-idfp',
  ];
  const query = {
    client_id: chototId.clientId,
    scopes: SCOPES,
    continue: continueUrl || chototId.callback,
  };
  const extension = process.env.ENV || 'prod';
  let cookieStr = '';
  if (cookies) {
    for (const cookieName of allowCookies) {
      if (cookies[cookieName]) {
        cookieStr += `${cookieName}=${cookies[cookieName]};`;
      }
    }
  }

  const res = await fetch(
    `http://ct-api-id.user.svc.gke1.ct.${extension}/v1/api/public/authorize?${new URLSearchParams(
      query
    ).toString()}`,
    {
      headers: {
        Cookie: cookieStr,
        ...headers,
      },
    }
  );
  const json = await res.json();

  if (res.status !== 200) {
    throw json;
  }

  return json;
};

const useLogin = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({
    phone,
    password = '',
    loginChallenge,
    fingerprint = '',
    otp = '',
    fingerprintV2 = '',
  }) => {
    const data = {
      phone,
      password,
      login_challenge: loginChallenge,
      otp,
    };

    return originFetch('/public/phone/login', {
      baseUrl: '/api',
      method: 'POST',
      headers: {
        accept: 'application/json;version=2',
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(data),
    });
  };

  return { ...rest, fetch };
};

const useLoginViaFacebook = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({ token, loginChallenge, fingerprint = '', fingerprintV2 = '' }) => {
    const data = {
      app_id: fbAppId,
      facebook_token: token,
      login_challenge: loginChallenge,
    };

    return originFetch('/public/facebook/login', {
      baseUrl: '/api',
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(data),
    });
  };

  return { ...rest, fetch };
};

const useLoginViaGoogle = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({ token, loginChallenge, fingerprint = '', fingerprintV2 = '' }) => {
    const data = {
      id_token: token,
      login_challenge: loginChallenge,
    };

    return originFetch('/public/google/login', {
      baseUrl: '/api',
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(data),
    });
  };

  return { ...rest, fetch };
};

const useLoginViaApple = () => {
  const { originFetch, ...rest } = useRequest();

  const fetch = ({
    accessToken,
    authorizationCode,
    userId,
    loginChallenge,
    fingerprint = '',
    fingerprintV2 = '',
  }) => {
    const data = {
      apple_access_token: accessToken,
      apple_authorization_code: authorizationCode,
      apple_user_id: userId,
      login_challenge: loginChallenge,
      origin: 'web',
    };

    return originFetch('/public/apple/login', {
      baseUrl: '/api',
      method: 'POST',
      headers: {
        [FINGERPRINT_HEADER]: fingerprint,
        [FINGERPRINT_HEADER_V2]: fingerprintV2,
      },
      body: JSON.stringify(data),
    });
  };

  return { ...rest, fetch };
};

export {
  useLogin,
  useAuthorize,
  useLoginViaFacebook,
  useLoginViaGoogle,
  useLoginViaApple,
  authozieOnServer,
};
